import {
  api,
  createCrudService,
  responseToOptions,
  responseToOptionsInContext,
  helpers
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService('/api/controltower/takeUpSchedules', api, {
  importLogUrl: '/api/controltower/importLog'
})

service.getProducerMonitoringId = async (id) => {
  try {
    const response = await api.get(
      `/api/controltower/takeUpSchedules/producerMonitoringId/${id}`
    )
    return response?.data
  } catch (error) {
    console.log(error)
    return { error }
  }
}

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  helpers.defineTakeUpLocality(
    ctx.cityByState,
    fieldsById,
    'takeUpState',
    'takeUpCity'
  )
  Object.keys(ctx.controllerOptionsByCompany).forEach((x) => {
    ctx.controllerOptionsByCompany[x] = responseToOptions(
      ctx.controllerOptionsByCompany[x]
    )
  })
  Object.keys(ctx.farmOptionsByProducerId).forEach((x) => {
    ctx.farmOptionsByProducerId[x] = responseToOptions(
      ctx.farmOptionsByProducerId[x]
    )
  })

  fieldsById.controller.options = async ({ itemData }) => {
    let idValue = itemData?.classifier
    const IdCompany = ctx.allCompany[String(idValue).toLocaleLowerCase()]
    return ctx.controllerOptionsByCompany[IdCompany] ?? []
  }

  fieldsById.warehouse.options = async ({ itemData }) => {
    if (itemData?.id == null) {
      return []
    }
    const producerMonitoringId = await service.getProducerMonitoringId(
      itemData.id
    )
    const producerId =
      ctx.producerIdByProducerMonitoringId[producerMonitoringId]
    return ctx.farmOptionsByProducerId[producerId] ?? []
  }
}

export default service
